<template>
  <div class="cookie-modal-wrapper">
    <div class="cookie-modal-header">
      <h4 class="cookie-modal-title">{{ t('cookies.modalTitle') }}</h4>
      <p class="cookie-modal-subtitle">
        {{ t('cookies.modalDescription') }}
        <a
          v-if="isShownCookieDocuments"
          class="cookie-modal-link"
          :href="cookiesPolicyUrl"
          target="_blank"
        >
          {{ t('cookies.politic') }}
        </a>
      </p>
    </div>
    <div class="cookie-modal-content">
      <StCollapse :initial-value="false">
        <template #name>
          <div class="header-wrapper">
            <h5 class="name">{{ t('cookies.requiredCookie') }}</h5>
            <span class="text-green">{{ t('cookies.alwaysActive') }}</span>
          </div>
        </template>
        <div class="collapse-content">
          {{ t('cookies.requiredCookieDescription') }}
        </div>
      </StCollapse>
      <StCollapse :initial-value="false">
        <template #name>
          <div class="header-wrapper">
            <h5 class="name">{{ t('cookies.functionalCookie') }}</h5>
            <StToggle
              v-model="functionalCookie"
              class="cookie-switcher"
              @click.stop
            />
          </div>
        </template>
        <div class="collapse-content">
          {{ t('cookies.functionalCookiesDescription') }}
        </div>
      </StCollapse>
      <StCollapse :initial-value="false">
        <template #name>
          <div class="header-wrapper">
            <h5 class="name">{{ t('cookies.analyticCookie') }}</h5>
            <StToggle
              v-model="analyticCookie"
              class="cookie-switcher"
              @click.stop
            />
          </div>
        </template>
        <div class="collapse-content">
          {{ t('cookies.analyticCookiesDescription') }}
        </div>
      </StCollapse>
    </div>
    <div class="cookie-modal-footer">
      <div class="cookie-modal-buttons">
        <StButton
          label="Принять все"
          size="xl"
          type="primary"
          @click="handleApplyAll"
        />
        <StButton
          label="Подтвердить"
          size="xl"
          type="gray"
          @click="handleApply"
        />
      </div>
      <StButton
        label="Отклонить все"
        size="xl"
        type="text-secondary"
        @click="handleReject"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCookiesStore } from '../../stores/useCookiesStore'

const cookiesPolicyUrl = usePublicFileUrl('publicFileCookiesPolicy')

const { isShownCookieDocuments } = useDomainFeatures()

const router = useRouter()
const { t } = useI18n()

const { functionalCookie, analyticCookie } = storeToRefs(useCookiesStore())
const { configCookie } = useCookiesStore()

function close() {
  router.replace({ query: { modal: '' } })
}

function handleApplyAll() {
  analyticCookie.value = true
  functionalCookie.value = true
  configCookie()
  close()
}

function handleApply() {
  configCookie()
  close()
}

function handleReject() {
  analyticCookie.value = false
  functionalCookie.value = false
  configCookie()
  close()
}
</script>

<style scoped>
.cookie-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);

  width: 596px;
  padding: var(--spacing-300);

  background: var(--background-base);
  border-radius: var(--border-radius-150);
  box-shadow: 0 0 80px -8px #1d1d2a;
}

.cookie-modal-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.cookie-modal-title {
  margin: 0;
  font: var(--desktop-text-2xl-semibold);
}

.cookie-modal-subtitle {
  margin: 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.cookie-modal-link {
  cursor: pointer;
  color: var(--text-link);

  &:hover {
    text-decoration: underline;
  }
}

.cookie-modal-content {
  padding: var(--spacing-300) 0;
}

.cookie-modal-footer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;

  & .st-button {
    flex-grow: 1;
  }
}

.cookie-modal-buttons {
  display: flex;
  gap: var(--spacing-150);
  width: 100%;
}

.text-green {
  font: var(--desktop-text-sm-semibold);
  color: var(--system-success);
}

.collapse-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);

  padding: var(--spacing-050);

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.cookie-switcher {
  margin-right: var(--spacing-400);
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.name {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}
</style>
